.main-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #f4f4f4;
    font-family: 'Open Sans', sans-serif;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: #f4f4f4;
}

p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
}

.contact-form label {
    font-size: 1rem;
    color: #f4f4f4;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
}

.contact-form textarea {
    resize: none;
}

.submit-button {
    background-color: #8B4513; /* Rich brown color for the button */
    color: #fff;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Poppins', sans-serif;
}

.submit-button:hover {
    background-color: #A0522D; /* Slightly lighter brown on hover */
}

.contact-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.contact-button {
    display: flex;
    align-items: center;
    background-color: #8B4513;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.contact-button:hover {
    background-color: #A0522D;
    color: #fff; /* Ensure text remains white on hover */
}

.icon {
    margin-right: 10px;
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
}

/* Remove default link styling */
.contact-button:link,
.contact-button:visited {
    color: #fff;
    text-decoration: none;
}

@media (max-width: 768px) {
    .contact-buttons {
        flex-direction: column;
        align-items: center;
    }

    .contact-button {
        width: 100%;
        justify-content: center;
    }
}