.simple-highlights {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px 20px;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
}

.highlight-item {
    text-align: center;
}

.highlight-item h1 {
    font-size: 5rem;
    margin: 0;
    font-weight: bold;
}

.highlight-item p {
    font-size: 1.2rem;
    margin-top: 10px;
    font-weight: 500;
}

/* Responsive Design */
@media (max-width: 768px) {
    .simple-highlights {
        flex-direction: column;
        text-align: center;
    }

    .highlight-item {
        margin-bottom: 20px;
    }

    .highlight-item h1 {
        font-size: 2.5rem;
    }

    .highlight-item p {
        font-size: 1rem;
    }
}
