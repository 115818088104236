/* Call-to-action section styling */
.call-to-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 20px;
    perspective: 1000px;
    min-height: 400px;
}

/* Card styling */
.cta-card {
    width: 80%;
    max-width: 800px;
    height: 300px;
    position: relative;
    overflow: hidden;
}

/* Card inner styling */
.cta-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    border-radius: 20px;
}

/* Card flip on hover */
.cta-card:hover .cta-card-inner {
    transform: rotateY(180deg);
}

/* Front and back of the card */
.cta-card-front,
.cta-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
}

/* Front card background */
.cta-card-front {
    background: linear-gradient(45deg, #8B4513, #D2691E);
    z-index: 2;
}

/* Back card background */
.cta-card-back {
    background: linear-gradient(45deg, #8B4513, #D2691E);
    transform: rotateY(180deg);
    overflow: hidden;
}

/* Text inside the back of the card */
.cta-card-back p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    padding: 10px;
    max-width: 90%;
}

/* Button styling */
.cta-card-back .cta-button {
    background-color: #FFD700;
    color: #8B4513;
    padding: 15px 30px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 30px; 
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}

.cta-card-back .cta-button:hover {
    background-color: #E5AC00; 
    color: #5A2A0A;
}

@media (max-width: 768px) {
    .cta-card {
        height: 250px;
    }
    
    .cta-card-back p {
        font-size: 1rem;
    }
    
    .cta-card-back .cta-button {
        padding: 10px 20px;
        font-size: 0.9rem;
    }
}