.about {
    padding: 60px 20px;
}

.about-content {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.about-text {
    flex: 1;
    padding-right: 20px;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-text h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #ffffff;
}

.about-text p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #ffffff;
    margin-bottom: 20px;
}

.about-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
}

.about-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 5px solid #E5AC00;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
    .about-content {
        flex-direction: column;
        align-items: center;
    }

    .about-text {
        padding-right: 0;
        padding-bottom: 20px;
        text-align: center;
    }

    .about-image {
        padding-left: 0;
    }

    .about-image img {
        height: auto;
        width: 100%;
    }
}
