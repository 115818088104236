.featured-collections {
    padding: 100px 20px;
    background-color: #fff;
}

.featured-collections h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 50px;
}

.collection-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
}

.collection-card {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease;
}

.collection-card:hover {
    transform: translateY(-10px);
}

.collection-card img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
}

.collection-card h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.view-details {
    color: #FF4500;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.view-details:hover {
    color: #FF5733;
}