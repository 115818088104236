.wrapper {
  position: relative;
  background-color: #151515;
  color: #f4f4f4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow: hidden;
}

.container {
  flex: 1;
  padding: 20px;
}

a {
  color: #00C896;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #00A57C;
}

h1, h2, h3, h4, h5, h6 {
  color: #ffffff;
  margin-top: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

p, a {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.navbar {
  background-color: #333;
  padding: 10px 20px;
}

.navbar a {
  color: #f4f4f4;
  margin-right: 15px;
  text-transform: uppercase;
}

.navbar a:hover {
  color: #00C896;
}

.footer {
  background-color: #333;
  color: #f4f4f4;
  padding: 20px;
  text-align: center;
  margin-top: auto;
}

.footer a {
  color: #00C896;
}

.footer a:hover {
  color: #00A57C;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

/* Blob Animation */
.blob {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  background: radial-gradient(circle at 50% 50%, #ff9900, #ff4500);
  filter: blur(100px);
  transform: translate(-50%, -50%);
  animation: blob-animation 30s infinite cubic-bezier(0.42, 0, 0.58, 1); /* Slow down, smoother timing */
  z-index: -2;
  opacity: 0.6;
}

@keyframes blob-animation {
  0% {
    border-radius: 50% 50% 50% 50%;
    transform: translate(-50%, -50%) scale(1.1);
  }
  15% {
    border-radius: 55% 45% 60% 40%;
    transform: translate(-45%, -55%) scale(1.4);
  }
  30% {
    border-radius: 65% 35% 50% 50%;
    transform: translate(-48%, -60%) scale(1.7);
  }
  45% {
    border-radius: 60% 40% 70% 30%;
    transform: translate(-55%, -50%) scale(1.3);
  }
  60% {
    border-radius: 40% 60% 60% 40%;
    transform: translate(-52%, -45%) scale(1.8);
  }
  75% {
    border-radius: 70% 30% 50% 50%;
    transform: translate(-50%, -58%) scale(1.5);
  }
  90% {
    border-radius: 55% 45% 45% 55%;
    transform: translate(-60%, -50%) scale(1.2);
  }
  100% {
    border-radius: 50% 50% 50% 50%;
    transform: translate(-50%, -50%) scale(1.1);
  }
}


