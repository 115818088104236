@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.navbar-custom {
  background-color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
}

.navbar-custom .navbar-brand,
.navbar-custom .nav-link {
  color: #fff;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0 15px;
}

.navbar-custom .navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-custom .navbar-nav {
  margin-left: auto;
}

.navbar-custom .nav-link {
  transition: color 0.3s ease;
  position: relative;
}

.navbar-custom .nav-link:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 2px;
  background: #F5A623;
  transition: width 0.3s ease, left 0.3s ease;
}

.navbar-custom .nav-link:hover:after {
  width: 100%;
  left: 0;
}

.navbar-custom .nav-link:hover {
  color: #F5A623;
}

.company-name {
  font-size: 1.8rem;
  color: #fff;
  font-weight: 500;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.navbar-toggle {
  border-color: transparent;
}

@media (max-width: 992px) {
  .navbar-custom .nav-link {
    padding: 10px 15px;
    font-size: 1rem;
  }
}
