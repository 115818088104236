.main-container {
  width: 100%;
  max-width: 1200px; /* Increase max-width to allow more space */
  padding: 20px;
  margin: 0 auto; /* Center the container */
  color: #f4f4f4;
  font-family: 'Open Sans', sans-serif;
}

.hero-container {
  text-align: center;
  margin-bottom: 40px;
}

.hero-container h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}

.hero-container p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distribute cards evenly with space between them */
  gap: 20px;
}

.service-card {
  background: linear-gradient(45deg, #8B4513, #D2691E);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: calc(33.333% - 20px); /* Adjust width to fit within the larger container */
  text-align: center;
  transition: transform 0.3s ease;
  margin-bottom: 20px; /* To create space between rows */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 250px; /* Set a minimum height for all cards */
}

.service-card h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #ffffff;
  margin-top: 0;
}

.service-card p {
  font-size: 1rem;
  color: #ffffff;
  margin: 10px 0 0;
  flex-grow: 1;
  align-self: stretch;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1024px) {
  .service-card {
    width: calc(50% - 20px);
    min-height: auto;
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .services-container {
      flex-direction: column;
      align-items: center;
  }

  .service-card {
      width: 90%;
      min-height: auto;
  }
}
