.hero-bar {
    position: relative;
    background-color: #000;
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 1000;
    text-align: left;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.hero-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    font-family: 'Courier New', Courier, monospace;
}

.terminal {
    color: #E5AC00;
    white-space: pre-wrap;
    font-size: 1.2rem;
}

.blinking-cursor {
    animation: blink 1s step-end infinite;
}

@keyframes blink {
    from, to { opacity: 1; }
    50% { opacity: 0; }
}

@media (max-width: 768px) {
    .terminal {
        font-size: 1rem;
    }
}
